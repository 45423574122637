import React, { FormEvent, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import axios from 'axios';
import { store } from '../../../../../Models/Store';
import {
	Button, Colors, Display, Sizes,
} from '../../../../Components/Button/Button';
import Tabs from '../../../../Components/Tabs/Tabs';
import { SERVER_URL } from '../../../../../Constants';
import alertToast from '../../../../../Util/ToastifyUtils';
import ScheduleTimeTab from './ScheduleTimeTab';
import { useQueryClient } from 'react-query';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

interface IPublishProfileModalProps {
	profileId?: string,
	zoneId?: string,
	canPublish: boolean,
	fetchScheduledDateTime: () => void
}

const parseScheduleDateTime = (publishDateTime: {date: Date, time: Date}) => {
	const selectedDate = publishDateTime.date.toDateString();
	const selectedTime = publishDateTime.time.toTimeString();

	return moment(`${selectedDate} ${selectedTime}`).utc(true).toDate();
};

const PublishProfileModal = ({
	profileId, zoneId, canPublish, fetchScheduledDateTime,
}: IPublishProfileModalProps) => {
	const [currentTab, setCurrentTab] = useState(0);
	const [disabledTracks, setDisabledTracks] = useState<string[]>([]);
	const [dislikedTracks, setDislikedTracks] = useState<string[]>([]);
	const [removeTracks, setRemoveTracks] = useState<boolean>(false);
	const [removeDislikedTracks, setRemoveDislikedTracks] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();

	const publishDateTime = {
		date: moment().add(1, 'day').toDate(),
		time: moment('1970-1-1 4:00').toDate(),
	};

	useEffect(() => {
		const fetchDisabledTracks = async () => {
			try {
				if (profileId) {
					const response = await axios.get(`${SERVER_URL}/api/disabledtrack/profile-disabled-tracks-list/${profileId}`);
					setDisabledTracks(response.data);
				}
			} catch (err) {
				alertToast('Error fetching disabled tracks. Please try again later or contact support!', 'error');
			}
		};
		fetchDisabledTracks();
	}, [profileId]);

	useEffect(() => {
		const fetchDislikedTracks = async () => {
			try {
				if (profileId) {
					const response = await axios.get(`${SERVER_URL}/api/disabledtrack/profile-disliked-tracks-list/${profileId}`);
					setDislikedTracks(response.data);
				}
			} catch (err) {
				alertToast('Error fetching disliked tracks. Please try again later or contact support!', 'error');
			}
		};
		fetchDislikedTracks();
	}, [profileId]);

	const closeModal = () => {
		fetchScheduledDateTime();
		store.modal.hide();
	};

	const publishZone = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!canPublish) {
			alertToast("You don't have permission to publish profiles!", 'error');
			return;
		}

		if (!zoneId) {
			return;
		}
		try {
			await axios.post(`${SERVER_URL}/api/sync/PublishZones`, {
				zoneIds: [zoneId],
				publishDateTime: currentTab === 0 ? parseScheduleDateTime(publishDateTime) : null,
			});
			alertToast('This zone is scheduled to be published', 'success');
			// TODO: need to refetch zone info, go to EditVenuePage...
		} catch (err) {
			alertToast('Error publishing zones. Please try again later or contact support!', 'error');
		} finally {
			closeModal();
		}
	};

	const publishProfile = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);

		if (!canPublish) {
			alertToast("You don't have permission to publish profiles!", 'error');
			setIsLoading(false);
			return;
		}

		if (!profileId) {
			alertToast('Cannot get profile ID', 'error');
			setIsLoading(false);
			return;
		}

		// format publish dateTime
		let formattedPublishDateTime = moment().utc(true)
			.set('second', 0)
			.set('millisecond', 0)
			.toDate();
		if (currentTab === 0) {
			formattedPublishDateTime = parseScheduleDateTime(publishDateTime);
		}

		try {
			// if both toggles are turned on
			if (removeTracks && removeDislikedTracks) {
				await axios.get(`${SERVER_URL}/api/disabledtrack/profile-disabled-disliked-tracks/${profileId}`);
			} else if (removeDislikedTracks) {
				await axios.get(`${SERVER_URL}/api/disabledtrack/profile-disliked-tracks/${profileId}`);
			} else if (removeTracks) {
				await axios.get(`${SERVER_URL}/api/disabledtrack/profile-disabled-tracks/${profileId}`);
			}

			await axios.post(`${SERVER_URL}/api/sync/PublishProfile`, {
				profileId,
				dateTime: formattedPublishDateTime,
				publishNow: currentTab === 1, // Tab 1 is publish now
			});

			queryClient.refetchQueries('profile');
			queryClient.refetchQueries('settings_stats');
			queryClient.refetchQueries('profile_summary');
			queryClient.refetchQueries('collection_contents');
			queryClient.refetchQueries('mood_contents');

			alertToast('Profile is scheduled to be published', 'success');
		} catch (err) {
			alertToast('Error publishing profile. Please try again later or contact support!', 'error');
		} finally {
			closeModal();
			setIsLoading(false);
		}
	};

	return (
		<div className="publish-modal-container">
			<h4>Publish</h4>
			<p id="sub-header">
				{`Would you like to schedule a time or publish 
				${zoneId ? 'this zone update' : 'all profile updates'} now?`}
			</p>

			<form onSubmit={zoneId ? publishZone : publishProfile}>

				<Tabs
					className="publish-tabs"
					tabs={[
						{
							component: <ScheduleTimeTab
								publishDateTime={publishDateTime}
								isPublishingZone={!!zoneId}
							/>,
							name: 'Schedule a time',
							className: 'publish-tab-content',
							key: 'scheduleTimeTab',
						},
						{
							component: <></>,
							name: 'Publish now',
							className: 'publish-tab-content',
							key: 'publishNowTab',
						},
					]}
					currentTab={currentTab}
					onTabClicked={tabIndex => setCurrentTab(tabIndex)}
				/>

				{disabledTracks.length > 0 && (
					<>
						<h5> Disabled mood tracks in this profile </h5>
						<ul className="track-list">
							{disabledTracks.map(track => (
								<li key={track}>{track}</li>
							))}
						</ul>

						<div className="profile-item">
							<p> Remove Disabled Tracks </p>
							<label className="disabled-disliked-switch" htmlFor="remove-disabled-toggle">
								<input
									type="checkbox"
									checked={removeTracks}
									onChange={() => setRemoveTracks(!removeTracks)}
									id="remove-disabled-toggle"
								/>
								<span
									className="slider round"
								/>

							</label>
						</div>
					</>
				)}

				{dislikedTracks.length > 0 && (
					<>
						<h5> Disliked mood tracks in this profile </h5>
						<ul className="track-list">
							{dislikedTracks.map(track => (
								<li key={track}>{track}</li>
							))}
						</ul>

						<div className="profile-item">
							<p className="p-profile"> Remove Disliked tracks </p>
							<label className="disabled-disliked-switch" htmlFor="remove-disliked-toggle">
								<input
									type="checkbox"
									checked={removeDislikedTracks}
									onChange={() => setRemoveDislikedTracks(!removeDislikedTracks)}
									id="remove-disliked-toggle"
								/>
								<span
									className="slider round"
								/>
							</label>
						</div>
					</>
				)}

				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={closeModal}
					>
						Cancel
					</Button>

					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
						disabled={isLoading}
					>
						Confirm
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(PublishProfileModal);
