import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SERVER_URL } from '../../../../../Constants';
import {
	Button, Colors, Display, Sizes,
} from '../../../../Components/Button/Button';
import { ZoneEntity } from '../../../../../Models/Entities';
import { store } from '../../../../../Models/Store';
import ZoneSyncSection from './ZoneSyncSection';
import axios from 'axios';

interface IZoneTileProps {
    zone: ZoneEntity;
    openEditZoneModal: () => void;
}

const ZoneTile = observer(({ zone, openEditZoneModal }: IZoneTileProps) => {
	const [connectionStatus, setConnectionStatus] = useState({ isConnected: false });

	useEffect(() => {
		axios.get(`${SERVER_URL}/api/entity/ZoneEntity/connection-status/${zone.id}`)
			.then(res => {
				setConnectionStatus({
					isConnected: res.data.isConnected,
				});
			})
			.catch(err => {
				console.log('err', err);
				setConnectionStatus({ ...connectionStatus, isConnected: false });
			});
	}, [connectionStatus, zone.id]);

	const activeStatusStyles = () => {
		const styles = {} as { background?: string; };
		if (zone.logoId) {
			styles.background = `
                linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url(${SERVER_URL}/api/files/${zone.logoId})
            `;
		}
		return styles;
	};

	const returnZoneState = () => {
		if (!zone.isActive) {
			return ({ className: 'zone-inactive', label: 'Inactive' });
		}
		if (connectionStatus.isConnected) {
			return ({ className: 'zone-connected', label: 'Connected' });
		}
		return ({ className: 'zone-disconnected', label: 'Disconnected' });
	};

	return (
		<div className="zone-tile-wrapper">
			<div className="zone-tile-header">
				<div className="header-top" style={activeStatusStyles()}>
					<div className="active-status">
						<div className={returnZoneState().className} />
						<p>{returnZoneState().label}</p>
					</div>
					<Button
						buttonProps={{ id: `${zone.name.split(' ').join('-')}-edit-button` }}
						colors={Colors.Primary}
						display={Display.Text}
						sizes={Sizes.Small}
						disabled={store.userGroups.some(ug => ug.name === 'AgencyPlaylister')}
						onClick={openEditZoneModal}
					>
						Edit Zone
					</Button>
				</div>
			</div>
			<h5>{zone.name}</h5>
			<p>{zone.description}</p>

			<Button
				buttonProps={zone.profileId ? { id: `${zone.name.split(' ').join('-')}-profile-button` } : {}}
				colors={Colors.Primary}
				display={Display.Text}
				sizes={Sizes.Small}
				onClick={() => {
					store.routerHistory.push(
						zone.profileId
							? `/profile-management/edit/${zone.profileId}/${zone.scheduleId}`
							: '/profile-management',
					);
				}}
			>
				{zone.profileId ? 'Manage Profile' : 'Add Profile'}
			</Button>

			{!!zone.profileId && (
				<ZoneSyncSection
					zoneId={zone.id}
					lastUpdated={zone.lastUpdated}
					updateRequired={zone.updateRequired}
					syncStatus={zone.syncStatus}
				/>
			)}
		</div>
	);
});

export default ZoneTile;
