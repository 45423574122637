/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterZoneEntity from 'Models/Security/Acl/AgencyPlaylisterZoneEntity';
import AgencyAdminZoneEntity from 'Models/Security/Acl/AgencyAdminZoneEntity';
import MustardManagementZoneEntity from 'Models/Security/Acl/MustardManagementZoneEntity';
import MustardAdminZoneEntity from 'Models/Security/Acl/MustardAdminZoneEntity';
import ApplicationUserZoneEntity from 'Models/Security/Acl/ApplicationUserZoneEntity';
import VisitorsZoneEntity from 'Models/Security/Acl/VisitorsZoneEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IZoneEntityAttributes extends IModelAttributes {
	syncStatus: Enums.syncStatusType;
	name: string;
	websiteURL: string;
	description: string;
	agencyOwnerId: string;
	isActive: boolean;
	productKey: string;
	lastUpdated: Date;
	updateRequired: Date;
	isStreaming: boolean;
	fadeInDuration: number;
	fadeOutDuration: number;
	fadeVolume: number;
	isFadeOverridden: boolean;
	latitude: number;
	longitude: number;
	logoId: string;
	logo: Blob;
	archived: boolean;

	auditss: Array<
		| Models.AuditEntity
		| Models.IAuditEntityAttributes
	>;
	controlEventss: Array<
		| Models.ControlAuditEntity
		| Models.IControlAuditEntityAttributes
	>;
	favouritedBys: Array<
		| Models.FavouritedByFavouriteZone
		| Models.IFavouritedByFavouriteZoneAttributes
	>;
	feedbackss: Array<
		| Models.FeedbackEntity
		| Models.IFeedbackEntityAttributes
	>;
	managedBys: Array<
		| Models.ManagedByManagesZone
		| Models.IManagedByManagesZoneAttributes
	>;
	profileId?: string;
	profile?: Models.ProfileEntity | Models.IProfileEntityAttributes;
	scheduleId?: string;
	schedule?: Models.ScheduleEntity | Models.IScheduleEntityAttributes;
	venueId?: string;
	venue?: Models.VenueEntity | Models.IVenueEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ZoneEntity', 'Zone')
// % protected region % [Customise your entity metadata here] end
export default class ZoneEntity extends Model
	implements IZoneEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterZoneEntity(),
		new AgencyAdminZoneEntity(),
		new MustardManagementZoneEntity(),
		new MustardAdminZoneEntity(),
		new ApplicationUserZoneEntity(),
		new VisitorsZoneEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Sync Status'] off begin
	@observable
	@attribute<ZoneEntity, Enums.syncStatusType>()
	@CRUD({
		name: 'Sync Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.syncStatusTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.syncStatusTypeOptions),
		displayFunction: (attr: Enums.syncStatusType) => Enums.syncStatusTypeOptions[attr],
	})
	public syncStatus: Enums.syncStatusType;
	// % protected region % [Modify props to the crud options here for attribute 'Sync Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ZoneEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Website URL'] off begin
	@Validators.Url()
	@observable
	@attribute<ZoneEntity, string>()
	@CRUD({
		name: 'Website URL',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public websiteURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Website URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<ZoneEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<ZoneEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	@observable
	@attribute<ZoneEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Product Key'] off begin
	@Validators.Uuid()
	@observable
	@attribute<ZoneEntity, string>()
	@CRUD({
		name: 'Product Key',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public productKey: string;
	// % protected region % [Modify props to the crud options here for attribute 'Product Key'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Updated'] off begin
	@observable
	@attribute<ZoneEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDateTimeNoTz(a) })
	@CRUD({
		name: 'Last Updated',
		displayType: 'datetimepicker',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: e => AttrUtils.standardiseDate(e, { serialiseAs: 'dateTimeWithoutTz' }),
	})
	public lastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Update Required'] off begin
	@observable
	@attribute<ZoneEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDateTimeNoTz(a) })
	@CRUD({
		name: 'Update Required',
		displayType: 'datetimepicker',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: e => AttrUtils.standardiseDate(e, { serialiseAs: 'dateTimeWithoutTz' }),
	})
	public updateRequired: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Update Required'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Streaming'] off begin
	/**
	 * Is Streaming
	 */
	@Validators.Required()
	@observable
	@attribute<ZoneEntity, boolean>()
	@CRUD({
		name: 'Is Streaming',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isStreaming: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Streaming'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade In Duration'] off begin
	/**
	 * Fade In Duration
	 */
	@Validators.Numeric()
	@observable
	@attribute<ZoneEntity, number>()
	@CRUD({
		name: 'Fade In Duration',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeInDuration: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade In Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade Out Duration'] off begin
	/**
	 * Fade Out Duration
	 */
	@Validators.Numeric()
	@observable
	@attribute<ZoneEntity, number>()
	@CRUD({
		name: 'Fade Out Duration',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeOutDuration: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade Out Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade Volume'] off begin
	/**
	 * Fade Volume
	 */
	@Validators.Integer()
	@observable
	@attribute<ZoneEntity, number>()
	@CRUD({
		name: 'Fade Volume',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public fadeVolume: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade Volume'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Fade Overridden'] off begin
	/**
	 * Is Fade Overridden
	 */
	@observable
	@attribute<ZoneEntity, boolean>()
	@CRUD({
		name: 'Is Fade Overridden',
		displayType: 'checkbox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isFadeOverridden: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Fade Overridden'] end

	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] off begin
	@Validators.Min(-90)
	@Validators.Max(90)
	@Validators.Numeric()
	@observable
	@attribute<ZoneEntity, number>()
	@CRUD({
		name: 'Latitude',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public latitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] off begin
	@Validators.Min(-180)
	@Validators.Max(180)
	@Validators.Numeric()
	@observable
	@attribute<ZoneEntity, number>()
	@CRUD({
		name: 'Longitude',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public longitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Logo'] off begin
	@observable
	@attribute<ZoneEntity, string>({ file: 'logo' })
	@CRUD({
		name: 'Logo',
		displayType: 'file',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'logo',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Zone" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public logoId: string;

	@observable
	public logo: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Logo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute<ZoneEntity, boolean>()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Audits'] on begin
		name: 'Auditss',
		displayType: 'hidden',
		order: 190,
		referenceTypeFunc: () => Models.AuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'auditss',
			oppositeEntity: () => Models.AuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Audits'] end
	})
	public auditss: Models.AuditEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Control Events'] on begin
		name: 'Control Eventss',
		displayType: 'hidden',
		order: 190,
		referenceTypeFunc: () => Models.ControlAuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'controlEventss',
			oppositeEntity: () => Models.ControlAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Control Events'] end
	})
	public controlEventss: Models.ControlAuditEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Favourited By'] off begin
		name: 'Favourited By',
		displayType: 'reference-multicombobox',
		order: 210,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedByFavouriteZone,
		optionEqualFunc: makeJoinEqualsFunc('favouritedById'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'zoneEntity',
			oppositeEntityName: 'applicationUserEntity',
			relationName: 'favouriteZone',
			relationOppositeName: 'favouritedBy',
			entity: () => Models.ZoneEntity,
			joinEntity: () => Models.FavouritedByFavouriteZone,
			oppositeEntity: () => Models.ApplicationUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Favourited By'] end
	})
	public favouritedBys: Models.FavouritedByFavouriteZone[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Feedbacks'] on begin
		name: 'Feedbackss',
		displayType: 'hidden',
		order: 200,
		referenceTypeFunc: () => Models.FeedbackEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'feedbackss',
			oppositeEntity: () => Models.FeedbackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Feedbacks'] end
	})
	public feedbackss: Models.FeedbackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Managed By'] off begin
		name: 'Managed By',
		displayType: 'reference-multicombobox',
		order: 230,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ManagedByManagesZone,
		optionEqualFunc: makeJoinEqualsFunc('managedById'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'zoneEntity',
			oppositeEntityName: 'applicationUserEntity',
			relationName: 'managesZone',
			relationOppositeName: 'managedBy',
			entity: () => Models.ZoneEntity,
			joinEntity: () => Models.ManagedByManagesZone,
			oppositeEntity: () => Models.ApplicationUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Managed By'] end
	})
	public managedBys: Models.ManagedByManagesZone[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Profile'] off begin
		name: 'Profile',
		displayType: 'reference-combobox',
		order: 240,
		referenceTypeFunc: () => Models.ProfileEntity,
		// % protected region % [Modify props to the crud options here for reference 'Profile'] end
	})
	public profileId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public profile: Models.ProfileEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Schedule'] off begin
		name: 'Schedule',
		displayType: 'reference-combobox',
		order: 250,
		referenceTypeFunc: () => Models.ScheduleEntity,
		// % protected region % [Modify props to the crud options here for reference 'Schedule'] end
	})
	public scheduleId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public schedule: Models.ScheduleEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Venue'] off begin
		name: 'Venue',
		displayType: 'reference-combobox',
		order: 260,
		referenceTypeFunc: () => Models.VenueEntity,
		// % protected region % [Modify props to the crud options here for reference 'Venue'] end
	})
	public venueId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public venue: Models.VenueEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IZoneEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IZoneEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.syncStatus !== undefined) {
				this.syncStatus = attributes.syncStatus;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.websiteURL !== undefined) {
				this.websiteURL = attributes.websiteURL;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.productKey !== undefined) {
				this.productKey = attributes.productKey;
			}
			if (attributes.lastUpdated !== undefined) {
				if (attributes.lastUpdated === null) {
					this.lastUpdated = attributes.lastUpdated;
				} else {
					this.lastUpdated = moment(attributes.lastUpdated).toDate();
				}
			}
			if (attributes.updateRequired !== undefined) {
				if (attributes.updateRequired === null) {
					this.updateRequired = attributes.updateRequired;
				} else {
					this.updateRequired = moment(attributes.updateRequired).toDate();
				}
			}
			if (attributes.isStreaming !== undefined) {
				this.isStreaming = attributes.isStreaming;
			}
			if (attributes.fadeInDuration !== undefined) {
				this.fadeInDuration = attributes.fadeInDuration;
			}
			if (attributes.fadeOutDuration !== undefined) {
				this.fadeOutDuration = attributes.fadeOutDuration;
			}
			if (attributes.fadeVolume !== undefined) {
				this.fadeVolume = attributes.fadeVolume;
			}
			if (attributes.isFadeOverridden !== undefined) {
				this.isFadeOverridden = attributes.isFadeOverridden;
			}
			if (attributes.latitude !== undefined) {
				this.latitude = attributes.latitude;
			}
			if (attributes.longitude !== undefined) {
				this.longitude = attributes.longitude;
			}
			if (attributes.logo !== undefined) {
				this.logo = attributes.logo;
			}
			if (attributes.logoId !== undefined) {
				this.logoId = attributes.logoId;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.auditss !== undefined && Array.isArray(attributes.auditss)) {
				for (const model of attributes.auditss) {
					if (model instanceof Models.AuditEntity) {
						this.auditss.push(model);
					} else {
						this.auditss.push(new Models.AuditEntity(model));
					}
				}
			}
			if (attributes.controlEventss !== undefined && Array.isArray(attributes.controlEventss)) {
				for (const model of attributes.controlEventss) {
					if (model instanceof Models.ControlAuditEntity) {
						this.controlEventss.push(model);
					} else {
						this.controlEventss.push(new Models.ControlAuditEntity(model));
					}
				}
			}
			if (attributes.favouritedBys !== undefined && Array.isArray(attributes.favouritedBys)) {
				for (const model of attributes.favouritedBys) {
					if (model instanceof Models.FavouritedByFavouriteZone) {
						this.favouritedBys.push(model);
					} else {
						this.favouritedBys.push(new Models.FavouritedByFavouriteZone(model));
					}
				}
			}
			if (attributes.feedbackss !== undefined && Array.isArray(attributes.feedbackss)) {
				for (const model of attributes.feedbackss) {
					if (model instanceof Models.FeedbackEntity) {
						this.feedbackss.push(model);
					} else {
						this.feedbackss.push(new Models.FeedbackEntity(model));
					}
				}
			}
			if (attributes.managedBys !== undefined && Array.isArray(attributes.managedBys)) {
				for (const model of attributes.managedBys) {
					if (model instanceof Models.ManagedByManagesZone) {
						this.managedBys.push(model);
					} else {
						this.managedBys.push(new Models.ManagedByManagesZone(model));
					}
				}
			}
			if (attributes.profileId !== undefined) {
				this.profileId = attributes.profileId;
			}
			if (attributes.profile !== undefined) {
				if (attributes.profile === null) {
					this.profile = attributes.profile;
				} else if (attributes.profile instanceof Models.ProfileEntity) {
					this.profile = attributes.profile;
					this.profileId = attributes.profile.id;
				} else {
					this.profile = new Models.ProfileEntity(attributes.profile);
					this.profileId = this.profile.id;
				}
			}
			if (attributes.scheduleId !== undefined) {
				this.scheduleId = attributes.scheduleId;
			}
			if (attributes.schedule !== undefined) {
				if (attributes.schedule === null) {
					this.schedule = attributes.schedule;
				} else if (attributes.schedule instanceof Models.ScheduleEntity) {
					this.schedule = attributes.schedule;
					this.scheduleId = attributes.schedule.id;
				} else {
					this.schedule = new Models.ScheduleEntity(attributes.schedule);
					this.scheduleId = this.schedule.id;
				}
			}
			if (attributes.venueId !== undefined) {
				this.venueId = attributes.venueId;
			}
			if (attributes.venue !== undefined) {
				if (attributes.venue === null) {
					this.venue = attributes.venue;
				} else if (attributes.venue instanceof Models.VenueEntity) {
					this.venue = attributes.venue;
					this.venueId = attributes.venue.id;
				} else {
					this.venue = new Models.VenueEntity(attributes.venue);
					this.venueId = this.venue.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		managedBys {
			${Models.ManagedByManagesZone.getAttributes().join('\n')}
			managedBy {
				${Models.ApplicationUserEntity.getAttributes().join('\n')}
				${Models.ApplicationUserEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		feedbackss {
			${Models.FeedbackEntity.getAttributes().join('\n')}
		}
		profile {
			${Models.ProfileEntity.getAttributes().join('\n')}
		}
		schedule {
			${Models.ScheduleEntity.getAttributes().join('\n')}
		}
		venue {
			${Models.VenueEntity.getAttributes().join('\n')}
			${Models.VenueEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			managedBys: {},
			auditss: {},
			feedbackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'auditss',
							'feedbackss',
							'managedBys',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public zoneVenueExpands = `
		venue {
			${Models.VenueEntity.getAttributes().join('\n')}
			${Models.VenueEntity.getFiles().map(f => f.name).join('\n')}

			zoness {
				${Models.ZoneEntity.getAttributes().join('\n')}
				${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`;
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ZoneEntity.prototype, 'created');
CRUD(modifiedAttr)(ZoneEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
