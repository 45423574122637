/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  SyntheticEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import { TextField } from '../../../Components/TextBox/TextBox';
import {
  AlbumEntity,
  ArtistEntity,
  GenreEntity,
  PlaylistEntity,
  TagEntity,
  TrackEntity,
} from '../../../../Models/Entities';
import {
  Button,
  Colors,
  Display,
  Sizes,
} from '../../../Components/Button/Button';
import FilterModal, { FilterForm } from './FilterModal';
import { store } from '../../../../Models/Store';
import TrackTile from '../TrackTile';
import AlbumArtPlaceholder from 'assets/empty-album-art.svg';
import {
  CollectionResult,
  CollectionType,
  CustomTabObject,
  MultiSelectStore,
} from 'Util/PlaylistUtils';
import CustomSpinner from 'Views/Components/CustomSpinner/CustomSpinner';

interface SearchPanelProps {
  fetchSearch: (options?: {
    pageNo: number;
    pageSize: number;
    filters?: FilterForm;
  }) => void;
  searchStore: {
    term: string;
  };
  openTab: (tab: CustomTabObject) => void;
  resultStore: CollectionResult;
  panelStore: {
    show: CollectionType;
    isExpanded: boolean;
    hasExpanded: boolean;
  };
  multiSelect: (
    event: MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: MultiSelectStore;
  applyFilter: (filter: FilterForm) => void;
  filterStore: FilterForm;
  showCount?: (shouldShowCount: number, tab: boolean, id: string) => void;
  filterCountStore?: {
    showCount: number;
    showTabCount: { [key: string]: number };
  };
  customTrackClickEvent: (
    track: TrackEntity,
    e: MouseEvent<HTMLInputElement>
  ) => void;
  isFetchingResult: boolean;
}

const SearchPanel = observer((props: SearchPanelProps) => {
  const {
    fetchSearch,
    openTab,
    searchStore,
    resultStore,
    panelStore,
    multiSelect,
    multiSelectStore,
    applyFilter,
    filterStore,
    showCount,
    filterCountStore,
    customTrackClickEvent,
    isFetchingResult,
  } = props;

  const onKeyDown = (
		e: MouseEvent | KeyboardEvent,
		snapshot: DraggableStateSnapshot,
	) => {
		if (e.defaultPrevented || snapshot.isDragging) return;
		e.preventDefault();
	};

  const SearchResultsSection = ({
    title,
    collectionType,
    collection = [],
    customDisplay,
    hasNoResult = false,
    isLoading,
  }: {
    title: string;
    collectionType: CollectionType;
    collection?:
      | TagEntity[]
      | PlaylistEntity[]
      | ArtistEntity[]
      | GenreEntity[]
      | AlbumEntity[];
    customDisplay?: ReactNode;
    hasNoResult?: boolean;
    isLoading: boolean
  }) => (
    <div className="result-section">
      <div className="result-section-header">
        <h4>{title}</h4>
        {(collection.length > 0 || !hasNoResult) && (
          <Button
            className="see-all"
            display={Display.Text}
            colors={Colors.Primary}
            onClick={() => runInAction(() => {
                panelStore.show = collectionType;
              })}
          >
            See All
          </Button>
        )}
      </div>

      {!!customDisplay ? (
        customDisplay
      ) : (
        <>
          {hasNoResult
            && searchStore.term !== ''
            && collection.length === 0 && (
              <div className="result-item no-result">No Result</div>
            )}
            {isLoading && collection.length === 0 && <CustomSpinner />}
            {collection.length === 0 && !isLoading && <p style={{ textAlign: 'center' }}>No result found</p>}
          { collection.map(item => (
            <div
              className={`result-item ${collectionType}`}
              key={`${collectionType}-${item.id}`}
              data-testid={`open-${collectionType}-${(item as TagEntity).name || (item as AlbumEntity).title}`}
              onClick={() => {
                openTab({
                  name: (item as TagEntity).name || (item as AlbumEntity).title,
                  entityType: collectionType,
                  id: item.id,
                  primaryGenre: collectionType === 'primaryGenres' ? (item as TagEntity).name : undefined,
                });
              }}
            >
              <p>{(item as TagEntity).name || (item as AlbumEntity).title}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div
      className={`search-panel-container${
        panelStore.isExpanded ? ' expanded' : ''
      }${panelStore.hasExpanded ? '' : ' start-open'}`}
      data-testid="search-panel"
    >
      <div className="search">
        <h4>Browse Music</h4>
        <div className="search-filter-container">
          <TextField
            model={searchStore}
            modelProperty="term"
            className="search-term search-panel"
            placeholder="Search"
            onAfterChange={(e: SyntheticEvent) => fetchSearch()}
          />
          <Button
            className={`search-filter${
              filterCountStore && filterCountStore.showCount > 0
                ? ' has-filter-count'
                : ''
            }`}
            data-testid="filter-button-search-panel"
            display={Display.Solid}
            sizes={Sizes.Medium}
            colors={Colors.Grey}
            icon={{ icon: 'filter', iconPos: 'icon-left' }}
            onClick={() => {
              runInAction(() => {
                panelStore.show = 'tracks';
              });
              store.modal.show(
                'Search Filter',
                <FilterModal
                  applyFilter={applyFilter}
                  filterStore={filterStore}
                  filterCountStore={filterCountStore}
                  showCount={showCount}
                  tab={false}
                  id={'search'}
                />,
                { className: 'slideout-panel-right' },
              );
            }}
          >
            {filterCountStore && filterCountStore.showCount > 0 && (
              <div className="filter-count">{filterCountStore.showCount}</div>
            )}
          </Button>
        </div>
      </div>
      {searchStore.term === '' ? (
        <div className="search-placeholder">
          <h5>Search for playlists, genres, songs, albums or artists.</h5>
        </div>
      ) : (
        <div className="results">
          <SearchResultsSection
            title="Playlists"
            collectionType="playlists"
            collection={resultStore.playlists}
            isLoading={isFetchingResult}
          />

          <SearchResultsSection
            title="Primary Genres"
            collectionType="primaryGenres"
            collection={resultStore.primaryGenres}
            isLoading={isFetchingResult}
          />

          <SearchResultsSection
            title="Secondary Genres"
            collectionType="genres"
            collection={resultStore.genres}
            isLoading={isFetchingResult}
          />

          <SearchResultsSection
            title="Songs"
            collectionType="tracks"
            hasNoResult={
              searchStore.term !== '' && resultStore.tracks.length === 0
            }
            isLoading={isFetchingResult}
            customDisplay={(
              <Droppable
                droppableId="search-panel-tracks"
                isDropDisabled
                renderClone={(provided, snapshot, rubric) => (
                  <div
                    className="track-wrapper-clone"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="select-count">
                      {multiSelectStore?.numSelected}
                    </div>

                    <img
                      src={AlbumArtPlaceholder}
                      alt="album-art"
                      className="album-art"
                    />
                  </div>
                )}
              >
                {outerProvided => (
                  <div
                    ref={outerProvided.innerRef}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...outerProvided.droppableProps}
                  >
                    {isFetchingResult && resultStore.tracks.length === 0 && <CustomSpinner />}
                    {resultStore.tracks.map(
                      (track: TrackEntity, index: number) => (
                        <Draggable
                          draggableId={`search-${track.id}`}
                          index={index}
                          key={`search-${track.id}`}
                          isDragDisabled={
                            !multiSelectStore.selected.includes(track.id)
                          }
                        >
                          {(
                            innerProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot,
                          ) => (
                            <div
                              className={`result-item track ${
                                multiSelectStore.selected.includes(track.id)
                                  ? 'selected'
                                  : ''
                              }`}
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              {...innerProvided.dragHandleProps}
                              onKeyDown={(e: KeyboardEvent) => onKeyDown(e, snapshot)}
                              onClick={(e: MouseEvent<HTMLInputElement>) => {
                                customTrackClickEvent(track, e);

                                e.preventDefault();
                                const isStart = e.ctrlKey || !e.shiftKey;
                                multiSelect(
                                  e,
                                  index,
                                  isStart,
                                  track.id,
                                  resultStore.tracks.map(t => t.id),
                                  'searchPanel',
                                );
                              }}
                            >
                              <TrackTile track={track} />
                            </div>
                          )}
                        </Draggable>
                      ),
                    )}
                    {outerProvided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          />

          <SearchResultsSection
            title="Artists"
            collectionType="artists"
            collection={resultStore.artists}
            isLoading={isFetchingResult}
          />

          <SearchResultsSection
            title="Albums"
            collectionType="albums"
            collection={resultStore.albums}
            isLoading={isFetchingResult}
          />
        </div>
      )}
    </div>
  );
});

export default SearchPanel;
